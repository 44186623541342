import { NFTUtilsActions } from './Actions';

export const NFTUtilsReducer = (state, action) => {
    switch (action.type) {
        case NFTUtilsActions.CHANGE_TYPE:
            localStorage.setItem('selectedCategory', action['payload']);
            return {
                ...state,
                selectedCategory: action['payload'],
            };
    }
};
