import Interceptors from '../interceptors';
let interceptores = new Interceptors();

export const createNFT = async (data) => {
    return interceptores.POST('nft', data);
};
export const submitArtwork = async (data) => {
    return interceptores.POST('gallery', data);
};
export const deleteNFT = async (data) => {
    return interceptores.DELETEWITHDATA('nft/delete', data);
};
export const deleteNFTFromGallery = async (data) => {
    return interceptores.DELETEWITHDATA('gallery/delete', data);
};
export const burnNFTFromGallery = async (data) => {
    return interceptores.DELETEWITHDATA('gallery/burn', data);
};
export const getAllNFTLit = async (data) => {
    return interceptores.GETPARAMS('nft/list', data);
};
export const getFeatureNFTList = async (data) => {
    return interceptores.GETPARAMS('nft/feature-list', data);
};
export const getAllNFTGalleryLit = async (data) => {
    return interceptores.GETPARAMS('gallery/list', data);
};
export const getAllNFTGiveAwayList = async (data) => {
    return interceptores.GETPARAMS('giveaway/list', data);
};
export const getNFTDetails = async (data) => {
    return interceptores.GET(`nft/${data}`);
};
export const getNFTDetailsGallery = async (data) => {
    return interceptores.GET(`gallery/${data}`);
};
export const getTokenHistory = async (data) => {
    return interceptores.GET(`nft/history/${data}`);
};
export const editNftDetails = async (data) => {
    return interceptores.PATCH('nft/edit', data);
};
export const logHistory = async (data) => {
    return interceptores.PATCH('nft/history', data);
};
export const logHistoryGallery = async (data) => {
    return interceptores.PATCH('gallery/history', data);
};
export const makefavourite = async (data) => {
    return interceptores.POST('nft/favourite/add', data);
};
export const removefavourite = async (data) => {
    return interceptores.DELETEWITHDATA('nft/favourite/remove', data);
};
export const verifyProfilereq = () => {
    return interceptores.POST('users/apply');
};
export const reportusApi = async (data) => {
    return interceptores.POST(`nft/report`, data);
};
export const searchApi = async (data) => {
    return interceptores.GET(`nft/search?search_text=${data}`);
};
export const getShareLink = async (data) => {
    return interceptores.GET(`nft/share/link?token=${data}`);
};

export const getMarketPrice = async () => {
    return interceptores.GET('nft/market-price');
};

// Get Payout Details
export const getPayoutDetails = async (payoutToken) => {
    return interceptores.GET('gallery/payout/' + payoutToken);
};
// Save Payout Details
export const savePayoutDetails = async (data) => {
    return interceptores.POST('gallery/save-payout', data);
};

// Get Home Banner Data
export const getHomeBannerData = async () => {
    return interceptores.GET('nft/home-banner-data');
};

// Create giveaway
export const createGiveaway = async (data) => {
    return interceptores.POST('giveaway', data);
};

// Participate Giveaway
export const participateGiveaway = async (data) => {
    return interceptores.POST('giveaway/participate', data);
};

// Check Is Participated
export const checkIsParticipated = async (data) => {
    return interceptores.POST('giveaway/check-is-participated', data);
};

// Get giveaway data
export const getGiveawayData = async (data) => {
    return interceptores.POST('giveaway/get-giveaway-data', data);
};

// Get Participants List
export const getParticipantsList = async (data) => {
    return interceptores.POST('giveaway/get-participants-list', data);
};

// Get Selection Data
export const getSelectionData = async (data) => {
    return interceptores.POST('giveaway/get-selection-list', data);
};

// Set giveaway winner
export const setGiveawayWinner = async (data) => {
    return interceptores.POST('giveaway/set-giveaway-winner', data);
};

// Update giveaway start and end time
export const updateGiveawayTime = async (data) => {
    return interceptores.POST('giveaway/update-giveaway-time', data);
};

// Get Top Collection Data
export const getTopCollection = async (data) => {
    return interceptores.GET(
        `nft/top-collection?blockchain=${data.blockchain}&sortByDate=${data.sortByDate}&device_id=${data.device_id}`
    );
};

export const getNFTForSelection = async (data) => {
    return interceptores.GETPARAMS('nft/nft-selection-list', data);
};

export const shareOnPinterest = (data) => {
    return interceptores.POST('nft/share-pinterest', data);
};
export const listNftTransaction = (data, userId) => {
    return interceptores.POST(`nft/list-nft-transaction/${userId}`, data);
};
export const buyNftTransaction = (data) => {
    return interceptores.POST(`nft/buy-nft-transaction`, data);
};
export const giveawayTransfer = (req) => {
    return interceptores.POST(`giveaway/transfer/giveaway`, req);
};
export const burnNftCustom = (req) => {
    return interceptores.POST(`nft/burn-nft-custom`, req);
};

/* AI artwork */
export const getArtStyleData = async () => {
    return interceptores.GET('artwork/get-art-style');
};

export const getSavedAINFTForSelection = async (data) => {
    return interceptores.GETPARAMS('artwork/get-saved-artwork', data);
};

export const generateAIArt = async (prompt) => {
    return interceptores.POST('artwork/generate-artwork', prompt);
};

export const saveArtWorkAPI = async (data) => {
    return interceptores.POST('artwork/save', data);
};

export const getGemsPlanAi = async () => {
    return interceptores.GET(`artwork/get-ai-subscription-plans`);
};

export const purchaseAIArtwork = async (data) => {
    return interceptores.POST(`artwork/purchase-ai-subscription`, data);
};

export const purchasedAIArtworkHistory = async (data) => {
    return interceptores.POST(
        `artwork/ai-purchased-subscriptions?page_no=${data.page_no}`
    );
};

export const publishAIArtwork = async (data) => {
    return interceptores.POST(`artwork/create`, data);
};

export const getSavedArtWorkById = async (data) => {
    return interceptores.GETPARAMS(`artwork/get-saved-artworkByID`, data);
};

export const getActiveSubscriptionDeatil = async () => {
    return interceptores.GETPARAMS(`artwork/get-active-subscription-details`);
};

export const createGiveawayArtwork = async (data) => {
    return interceptores.POST('giveaway/createByURl', data);
};

export const shareOnPinterestSaveArt = (data) => {
    return interceptores.POST('nft/share-pinterest-api', data);
};

export const purchaseAIArtworkCustom = async (data) => {
    return interceptores.POST(`artwork/purchase-ai-subscriptioncustom`, data);
};

export const addToCartNft = async (data) => {
    return interceptores.POST(`nft/add-to-cart`, data);
};

export const nftCartList = async (req) => {
    return interceptores.POST(`nft/cart-list`, req);
};

export const removeCartItem = async (data) => {
    return interceptores.POST(`nft/remove-from-cart`, data);
};

export const cartLogHistory = async (data) => {
    return interceptores.POST(`nft/cart-log-history`, data);
};

export const buyCartNft = async (data) => {
    return interceptores.POST(`nft/purchase-from-cart`, data);
};

export const updateDeviceId = async (data) => {
    return interceptores.POST(`nft/update-device-id`, data);
};

export const getExploreNFTLit = async (data) => {
    return interceptores.GETPARAMS('nft/explore-list', data);
};

// New Changes

export const approveNFT = async (data) => {
    return interceptores.POST('nft/approve-nft', data);
};

export const rejectNFT = async (data) => {
    return interceptores.POST('nft/reject-nft', data);
};

// Artwork NFT Approve
export const approveArtworkNFT = async (data) => {
    return interceptores.POST('artwork/approve-nft', data);
};
export const rejectArtworkNFT = async (data) => {
    return interceptores.POST('artwork/reject-nft', data);
};
