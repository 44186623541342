import React from 'react';
import UserProvider from './User/Context';
import NFTProvider from './NFT/Context';
import NFTUtilsProvider from './NFTUtils/Context';
import UtilProvider from './Util/Context';
import AuthProvider from './Auth/Context';

const Store = (props) => {
    return (
        <UtilProvider>
            <AuthProvider>
                <UserProvider>
                    <NFTUtilsProvider>
                        <NFTProvider>{props.children}</NFTProvider>
                    </NFTUtilsProvider>
                </UserProvider>
            </AuthProvider>
        </UtilProvider>
    );
};

export default Store;
