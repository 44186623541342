import React from 'react';
import { AuthActions as AA } from './Actions';
import { AuthContext as AC } from './Context';

const UseAuth = () => {
    // const { utilState,
    //   showConnectWalletPopup,
    //   hideConnectWalletPopup  } = UseUtil();
    const { authState, setAuthCookies, disconnetWallet, dispatch } =
        React.useContext(AC);

    const loginUser = (userData) => {
        dispatch({ type: AA.LOGIN, payload: userData });
    };
    const logoutUser = () => {
        dispatch({
            type: AA.LOGOUT,
        });
    };
    const SearchLisShowEventManagment = (payload) => {
        dispatch({ type: AA.SET_SEARCH_LIST_SHOW, payload });
    };
    const SetSearchParameter = (payload) => {
        dispatch({ type: AA.SET_SEARCHPARAMETER, payload });
    };
    return {
        authState,
        logoutUser,
        SetSearchParameter,
        setAuthCookies,
        disconnetWallet,
        loginUser,
        SearchLisShowEventManagment,
    };
};

export default UseAuth;
