export const NFTActions = {
    GET_NFT: 'GET_NFT',
    ADD_FAV: 'ADD_FAV',
    DELETE_NFT: 'DELETE_NFT',
    PURCHASE_NFT: 'PURCHASE_NFT',
    SIGN_NFT: 'SIGN_NFT',
    REMOVE_FAV: 'REMOVE_FAV',
    SET_CREATE_NFT_DETAILS: 'SET_CREATE_NFT_DETAILS',
    SET_EXPLORE_NFT_DATA: 'SET_EXPLORE_NFT_DATA',
    SET_TOP_RATED_NFT_DATA: 'SET_TOP_RATED_NFT_DATA',
    SET_GALLERY_NFT_DATA: 'SET_GALLERY_NFT_DATA',
    SET_GIVEAWAY_NFT_DATA: 'SET_GIVEAWAY_NFT_DATA',
    SET_CATAGORY_NFT: 'SET_CATAGORY_NFT',
    ADD_NFT_DATA_EXPLORE: 'ADD_NFT_DATA_EXPLORE',
    SET_CREATED_NFT: 'SET_CREATED_NFT',
    SET_APPROVAL_NFT: 'SET_APPROVAL_NFT',
    SET_COLLECTED_NFT: 'SET_COLLECTED_NFT',
    SET_SAVE_ITEM_NFT: 'SET_SAVE_ITEM_NFT',
    SET_SOLD_NFT: 'SET_SOLD_NFT',
    SET_SALES_NFT: 'SET_SALES_NFT',
    SET_GALLERY_NFT: 'SET_GALLERY_NFT',
    SET_GIVEAWAY_NFT: 'SET_GIVEAWAY_NFT',
    CLR_NFT_DETAILS: 'CLR_NFT_DETAILS',
    SET_CREATED_NFT_INITIAL: 'SET_CREATED_NFT_INITIAL',
    SET_APPROVAL_NFT_INITIAL: 'SET_APPROVAL_NFT_INITIAL',
    SET_COLLECTED_NFT_INITIAL: 'SET_COLLECTED_NFT_INITIAL',
    SET_SAVE_ITEM_NFT_INITIAL: 'SET_SAVE_ITEM_NFT_INITIAL',
    SET_SOLD_NFT_INITIAL: 'SET_SOLD_NFT_INITIAL',
    SET_SALES_NFT_INITIAL: 'SET_SALES_NFT_INITIAL',
    SET_GALLERY_INITIAL: 'SET_GALLERY_INITIAL',
    SET_GIVEAWAY_INITIAL: 'SET_GIVEAWAY_INITIAL',
    SET_PAGINATION_CREATED_INTIAL: 'SET_PAGINATION_CREATED_INTIAL',
    SET_PAGINATION_APPROVAL_INTIAL: 'SET_PAGINATION_APPROVAL_INTIAL',
    SET_PAGINATION_COLLECTED_INTIAL: 'SET_PAGINATION_COLLECTED_INTIAL',
    SET_PAGINATION_SAVE_ITEM_NFT_INTIAL: 'SET_PAGINATION_SAVE_ITEM_NFT_INTIAL',
    SET_PAGINATION_SOLD_NFT_INTIAL: 'SET_PAGINATION_SOLD_NFT_INTIAL',
    SET_PAGINATION_SALES_NFT_INTIAL: 'SET_PAGINATION_SALES_NFT_INTIAL',
    SET_PAGINATION_GALLERY_INTIAL: 'SET_PAGINATION_GALLERY_INTIAL',
    SET_PAGINATION_GIVEAWAY_INTIAL: 'SET_PAGINATION_GIVEAWAY_INTIAL',

    UPDATE_NFT_DETAILS: 'UPDATE_NFT_DETAILS',
    SET_HEADER_DATA: 'SET_HEADER_DATA',
    DELETE_NFT_DATA: 'DELETE_NFT_DATA',
    SET_SEARCH_NFT_DATA: 'SET_SEARCH_NFT_DATA',
    CLEAR_NFT_DETAIL: 'CLEAR_NFT_DETAIL',

    /* Saved AI Artwork */
    SET_SAVED_AI_NFT: 'SET_SAVED_AI_NFT',
    SET_SAVED_AI_NFT_INITIAL: 'SET_SAVED_AI_NFT_INITIAL',
    SET_PAGINATION_SAVED_AI_INTIAL: 'SET_PAGINATION_SAVED_AI_INTIAL',

    /* Published AI Artwork */
    SET_PUBLISHED_AI_NFT: 'SET_PUBLISHED_AI_NFT',
    SET_PUBLISHED_AI_NFT_INITIAL: 'SET_PUBLISHED_AI_NFT_INITIAL',
    SET_PAGINATION_PUBLISHED_AI_INTIAL: 'SET_PAGINATION_PUBLISHED_AI_INTIAL',

    /* Approval AI Artwork */
    SET_APPROVAL_AI_NFT: 'SET_APPROVAL_AI_NFT',
    SET_APPROVAL_AI_NFT_INITIAL: 'SET_APPROVAL_AI_NFT_INITIAL',
    SET_PAGINATION_APPROVAL_AI_INTIAL: 'SET_PAGINATION_APPROVAL_AI_INTIAL',

    SET_TOP_COLLECTION_NFT_DATA: 'SET_TOP_COLLECTION_NFT_DATA',
    UPDATE_TOP_COLLECTION_DATA: 'UPDATE_TOP_COLLECTION_DATA',

    SET_CART_LIST_DATA: 'SET_CART_LIST_DATA',
    UPDATE_CART_LIST_DATA: 'UPDATE_CART_LIST_DATA',

    UPDATE_NFT_DATA: 'UPDATE_NFT_DATA',
};
