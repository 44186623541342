export const UserInitialState = {
    userProfileData: {
        name: '',
        username: '',
        email: '',
        profile_photo: '',
        bio: '',
        links: '',
        public_address: '',
        social_follower_cnt: '',
        is_verified: 0,
        is_profile_completed: 0,
        is_referral_modal_load: 0,
    },
    userprofilePageData: {
        bio: '',
        email: '',
        facebook_url: '',
        id: 11,
        instagram_url: '',
        is_profile_completed: 0,
        is_verified: 0,
        is_your: 0,
        name: '',
        nonce: 0,
        profile_photo: '',
        profile_photo_thumb: '',
        public_address: '',
        social_follower_cnt: '',
        twitter_url: '',
        username: '',
        website_url: '',
    },
};
