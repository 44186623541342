const initialState = {
    topRated: [],
    topGalleryData: [],
    topGiveawayData: [],
    explore: [],
    collected: [],
    savedAI: [],
    publishedAI: [],
    approvalAI: [],
    created: [],
    nft_in_approval: [],
    fav: [],
    soldOut: [],
    sales: [],
    gallery: [],
    giveaway: [],
    collections: {},
    items: {},
    useres: {},
    collectionDataPagination: {
        page_no: 0,
        page_size: 9,
        count: 0,
        currentCount: 0,
    },
    itemsPagination: {
        page_no: 0,
        page_size: 9,
        count: 0,
        currentCount: 0,
    },
    useresPagination: {
        page_no: 0,
        page_size: 9,
        count: 0,
        currentCount: 0,
    },
    headerData: null,
    nftFormDetails: {
        is_collection: 0,
        category: '',
        assets: [],
        collection_thumb: null,
        extra_music: null,
        price: '',
        unlock_data: '',
        on_resell: 1,
        title: '',
        description: '',
        royalty: 10,
        total_copies: 1,
        props: '',
        alt_text: '',
    },
    nftPageDetails: null,
    catagorySelected: 'ALL',
    explorePagination: {
        page_no: 0,
        page_size: 12,
        count: 0,
        currentCount: 0,
    },
    favPagination: {
        page_no: 0,
        page_size: 9,
        count: 0,
        currentCount: 0,
    },
    createdPagination: {
        page_no: 0,
        page_size: 9,
        count: 0,
        currentCount: 0,
    },
    nft_in_approval_pagination: {
        page_no: 0,
        page_size: 9,
        count: 0,
        currentCount: 0,
    },
    savedAIPagination: {
        page_no: 0,
        page_size: 9,
        count: 0,
        currentCount: 0,
    },
    publishedAIPagination: {
        page_no: 0,
        page_size: 9,
        count: 0,
        currentCount: 0,
    },
    approvalAIPagination: {
        page_no: 0,
        page_size: 9,
        count: 0,
        currentCount: 0,
    },
    collectedPagination: {
        page_no: 0,
        page_size: 9,
        count: 0,
        currentCount: 0,
    },
    soldOutPagination: {
        page_no: 0,
        page_size: 9,
        count: 0,
        currentCount: 0,
    },
    salestPagination: {
        page_no: 0,
        page_size: 9,
        count: 0,
        currentCount: 0,
    },
    galleryPagination: {
        page_no: 0,
        page_size: 12,
        count: 0,
        currentCount: 0,
    },
    giveawayPagination: {
        page_no: 0,
        page_size: 12,
        count: 0,
        currentCount: 0,
    },
    topCollectionData: [],
    updateTopCollectedData: [],

    cartListData: [],
    updateCartListData: [],
    updateNftData: [],
};

export { initialState };
