import Cookies from 'universal-cookie';
const cookie = new Cookies();

export const generateRandomId = (length) => {
    let result = '';
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
};

export const setDeviceId = () => {
    cookie.set('nfton_device_id', generateRandomId(16), {
        maxAge: 60 * 60 * 24 * 365,
        sameSite: true,
    });
};

export const getDeviceId = () => {
    let device_id = cookie.get('nfton_device_id');
    if (!device_id) {
        setDeviceId();
        device_id = cookie.get('nfton_device_id');
    }
    return device_id;
};
