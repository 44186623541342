import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN =
    process.env.SENTRY_DSN ||
    process.env.NEXT_PUBLIC_SENTRY_DSN ||
    'https://5e2751e1307a4b4eb945596f1f9e71b8@o983200.ingest.sentry.io/5942621';
const SENTRY_ENV =
    process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV || 'local';
Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});
