import React from 'react';
import {
    getAllNFTLit,
    getAllNFTGalleryLit,
    getFeatureNFTList,
    getAllNFTGiveAwayList,
} from '../../lib/NFT';
import { NFTActions } from './Actions';
import { nftReducer } from './Reducer';
import { initialState } from './State';
import { useRouter } from 'next/router';
import UseAuth from '../../Contexts/Auth/useAuth';
import { getDeviceId } from '../../Hooks/getDeviceId';

export const nftContext = React.createContext({
    initialState,
    dispatch: () => null,
});

const NFTProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(nftReducer, initialState);
    const router = useRouter();
    const { enc_url } = router.query;
    const { authState } = UseAuth();

    const getExploreData = async () => {
        let Params = { page_no: 0, page_size: 12, device_id: getDeviceId() };

        if (
            enc_url &&
            enc_url !== '' &&
            enc_url !== 'null' &&
            enc_url !== 'undefined'
        ) {
            Params = {
                page_no: 0,
                page_size: 12,
                ipfs_value: enc_url,
                device_id: getDeviceId(),
            };
        }
        if (initialState.catagorySelected !== 'ALL') {
            Params = {
                ...Params,
                category: initialState.catagorySelected.toLowerCase(),
                device_id: getDeviceId(),
            };
        }
        let response = await getAllNFTLit(Params);
        if (response?.status === 200) {
            let datalist = [];
            // data.push(response.data.data[0]);
            let addeddata = response?.data?.data[0];
            datalist.push(addeddata);
            let dataarray = { data: datalist };
            dispatch({
                type: NFTActions.SET_EXPLORE_NFT_DATA,
                payload: response.data,
            });
        }
    };
    const getTopRatedNFTData = async () => {
        let Params = {
            key: 'top_rated',
            page_no: 0,
            page_size: 12,
            device_id: getDeviceId(),
        };
        if (initialState.catagorySelected !== 'ALL') {
            Params = {
                ...Params,
                category: initialState.catagorySelected.toLowerCase(),
                device_id: getDeviceId(),
            };
        }
        let response = await getFeatureNFTList(Params);
        if (response?.status === 200) {
            let data = [];
            // data.push(response.data.data[0]);
            let addeddata = response?.data?.data[0];
            data.push(addeddata);
            dispatch({
                type: NFTActions.SET_TOP_RATED_NFT_DATA,
                payload: response.data.data,
            });
        }
    };
    const getGalleryRecordsData = async () => {
        let Params = {
            key: 'top_rated',
            page_no: 0,
            page_size: 12,
            device_id: getDeviceId(),
        };
        if (initialState.catagorySelected !== 'ALL') {
            Params = {
                ...Params,
                category: initialState.catagorySelected.toLowerCase(),
                device_id: getDeviceId(),
            };
        }
        let response = await getAllNFTGalleryLit(Params);
        if (response?.status === 200) {
            let data = [];
            // data.push(response.data.data[0]);
            let addeddata = response?.data?.data[0];
            data.push(addeddata);
            dispatch({
                type: NFTActions.SET_GALLERY_NFT_DATA,
                payload: response.data.data,
            });
        }
    };
    const getGiveawayRecordsData = async () => {
        let Params = {
            key: 'top_rated',
            page_no: 0,
            page_size: 12,
            device_id: getDeviceId(),
        };
        if (initialState.catagorySelected !== 'ALL') {
            Params = {
                ...Params,
                category: initialState.catagorySelected.toLowerCase(),
            };
        }
        let response = await getAllNFTGiveAwayList(Params);
        if (response?.status === 200) {
            let data = [];
            // data.push(response.data.data[0]);
            let addeddata = response?.data?.data[0]
                ? response?.data?.data[0]
                : [];
            data.push(addeddata);
            dispatch({
                type: NFTActions.SET_GIVEAWAY_NFT_DATA,
                payload: response.data.data,
            });
        }
    };
    React.useEffect(() => {
        if (initialState.explore.length === 0) {
            getExploreData();
        }
        if (initialState.topRated.length === 0) {
            getTopRatedNFTData();
        }
        if (initialState.topGalleryData.length === 0) {
            getGalleryRecordsData();
        }
        if (initialState.topGiveawayData.length === 0) {
            getGiveawayRecordsData();
        }
    }, [
        initialState.explore,
        initialState.topRated,
        initialState.topGalleryData,
        initialState.topGiveawayData,
    ]);

    React.useEffect(() => {
        getGiveawayRecordsData();
    }, []);
    return (
        <nftContext.Provider value={{ nftState: state, dispatch }}>
            {children}
        </nftContext.Provider>
    );
};

export default NFTProvider;
