// Packages
import axios from 'axios';
// Cookies
import Cookies from 'universal-cookie';

const cookies = new Cookies();
class Interceptors {
    constructor() {
        // Add a request interceptor
        axios.interceptors.request.use(
            function (config) {
                const tokens = cookies.get('tokens');
                if (tokens)
                    config['headers']['authorization'] = tokens.accessToken;
                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
        // Add a response interceptor
        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                if (error.response.status === 401) {
                    cookies.remove('tokens', { path: '/' });
                    window.location.href = '/';
                }
                return Promise.reject(error);
            }
        );
    }
    GET(url) {
        return axios
            .get(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/` + url)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }
    POST(url, data) {
        return axios
            .post(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/` + url, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }

    PUT(url, data) {
        return axios
            .put(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/` + url, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }
    DELETEWITHDATA(url, data) {
        return axios
            .delete(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/` + url, {
                data: data,
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }
    DELETE(url) {
        return axios
            .delete(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/` + url)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }
    PATCH(url, data) {
        return axios
            .patch(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/` + url, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }
    GETPARAMS(url, parames) {
        return axios(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/` + url, {
            params: { ...parames },
        })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }
}

export default Interceptors;
